import React, {Component} from 'react';
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import {graphql } from "gatsby";
import get from 'lodash/get'
import FullWidthImage from "../components/core/components/FullWidthImage";
import { Container } from 'styled-bootstrap-grid';

class ContactPage extends Component {
  render() {
    const path = this.props.location.pathname;
    const page = get(this, 'props.data.page');

    return (
      <Layout contact={true}>
        <SEO title={page.title}
             description={page.metaDescription}
             path={path}/>
        <Container>
          <FullWidthImage img={page.heroImage.fluid} />
        </Container>
      </Layout>
    )
  }
}

export default ContactPage;

export const pageQuery = graphql`
  query ContactPageQuery {
    page: contentfulPage(slug: {eq: "contact"}) {
      id
      slug
      title
      metaDescription
      heroImage: heroImage {
        fluid(
          maxWidth: 1920
          quality: 70
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      blocks {
        __typename
        ... on Node {
          ... on ContentfulBlockIntro {
            id
            title
            content {
              json
            }
          }
          ... on ContentfulBlockTextImage {
            id
            imagePosition
            content {
              json
            }
            image: image {
              fluid(
                maxWidth: 1000
                quality: 70
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
